





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RequestStatusIcon extends Vue {
 @Prop()
 requestStatusId!: number;
}
