


























































import { TableColumn } from '@/models/TableColumn'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/Table.vue'
import FButton from '@/components/Button/FButton.vue'
import RegisterSongs from './RegisterSongs.vue'
import ModalRemoveSong from './ModalRemoveSong.vue'
import RequestStatusIcon from './RequestStatusIcon.vue';
import { RegisteredSongs } from '@/models/RegisteredSongs'
import { getRequestStatusMsg } from '@/configs/SongRegistrationConfig'
import { i18n } from '@/main'

@Component({ 
  components:{ 
    Table, 
    FButton, 
    RequestStatusIcon, 
    ModalRemoveSong,
  }
})
export default class RegisterSongTable extends Vue {
  @Prop({default: () => ([])})
  data!: RegisteredSongs[];

  get columns(): TableColumn[] {
    return [
      { 
        key: 'songInfo', 
        label: i18n.t('R.LIT_RecentSongInfo').toString(),

      },
      { 
        key: 'lastModified', 
        label: i18n.t('R.LIT_RecentLastModified').toString(),
        headerStyle: 'width:300px',
      },
      { 
        key: 'status', 
        label: i18n.t('R.LIT_RecentStatus').toString(),
        headerStyle: 'width:175px',
      },
      { 
        key: 'action',
        label: i18n.t('R.LIT_RecentSongAction').toString(), 
        headerStyle: 'width:105px',
      },
    ]; 
  }

  removeSong(song: RegisteredSongs){ 
    ((this.$parent as RegisterSongs).$refs.removeSongModal as ModalRemoveSong).show(song);
  }

  getStatus(song: RegisteredSongs) { 
    return getRequestStatusMsg(song.requestStatusId);
  }
}
