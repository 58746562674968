






























import { Component, Vue } from 'vue-property-decorator';
import RegisterSongTable from './RegisterSongTable.vue';
import ModalRemoveSong from './ModalRemoveSong.vue';
import FButton from '@/components/Button/FButton.vue';
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue';
import { BusyService } from '@/services/BusyService';
import { ApiService } from '@/services/ApiService';
import { ProfileService } from '@/services/ProfileService';
import { RequestStatus } from '@/configs/SongRegistrationConfig';
import { RegisteredSongs } from '@/models/RegisteredSongs';
import { bus, i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.registerSongsTitle
  }), 
  components:{ 
    PhraseLink,
    RegisterSongTable,
    ModalRemoveSong, 
    FButton
  }
})
export default class RegisterSongs extends Vue {
  incompleteActivity: RegisteredSongs[] = [];
  recentActivity: RegisteredSongs[]= [];

  mounted() { 
    this.getRegisteredSongs();  
  }

  async getRegisteredSongs() { 
    if (!ProfileService.profile){ 
      return;
    }
    BusyService.showBusy();
    const requests = await ApiService.post('/api/GetRegisteredSongs', { organizationId: ProfileService.profile.org.id}) as RegisteredSongs[];
    this.incompleteActivity = requests.filter( r => r.requestStatusId === RequestStatus.Saved );
    this.recentActivity = requests.filter( r => r.requestStatusId !== RequestStatus.Saved );
    BusyService.hideBusy();
  }

  onDelete(){ 
    this.getRegisteredSongs();
  }
  showContactModal() { 
    bus.$emit('showContactInfo');
  }
}
