
















































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue';
import { RegisteredSongs } from '@/models/RegisteredSongs';
import { getRequestStatusMsg, getRequestTypeMsg } from '@/configs/SongRegistrationConfig';
import { BusyService } from '@/services/BusyService';
import { ToastService } from '@/services/ToastService';
import { ApiService } from '@/services/ApiService';

@Component({ 
  components:{ 
    Modal
  }
})
export default class ModalRemoveSong extends Vue {
  song: RegisteredSongs | null = null;

  show(song: RegisteredSongs){ 
    this.song = song;
    (this.$refs.modal as Modal).show();
  }

  get requestType() { 
    if (!this.song){ 
      return '';
    }
    return getRequestTypeMsg(this.song.requestTypeId);
  }

  get status() { 
    if (!this.song){ 
      return '';
    }
    
    return getRequestStatusMsg(this.song.requestStatusId);
  }

  async onOk() { 
    if (!this.song){ 
      return;
    }
    BusyService.showBusy();
    await ApiService.post('/api/DeleteRegisterSongData', { requestId: this.song.requestId})
    ToastService.successToast(this.$t('R.LIT_SongDeleted').toString());
    (this.$refs.modal as Modal).hide();
    this.$emit('delete');
  }
}
